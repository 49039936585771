import React, { useState, useEffect } from "react";
import "./showcase.styles.scss";
import { Link } from "react-router-dom";
import ShopItem from "../shop-item/shop-item.component";
import SHOP_DATA from "../../pages/shop/shop.data";
import { shopFilterAnimation, ShopScrollAnimation } from "../../js/animations";

const ItemsShowcase = ({ filtered, category }) => {
  const [furniture, setFurniture] = useState(SHOP_DATA);

  useEffect(() => {
    shopFilterAnimation();
    ShopScrollAnimation();
  }, []);

  const shopCategories = Object.keys(furniture);
  const allItemsInShop = Object.values(furniture).flatMap((category) => category.items);
  const isFiltered = filtered ? true : false;

  return (
    <>
      <div className="filter">
        <div className="filter__hide">&#10005;</div>
        <ul>
          <li>
            <Link to="/collections">Everything</Link>
          </li>
          {shopCategories.map((category) => (
            <li key={category}>
              <Link to={`/category/${category.toLowerCase()}`}>
                {category}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="showcase-container">
        <div className="showcase">
          {isFiltered
            ? furniture[category].items.map((item) => (
                <ShopItem
                  key={item.id}
                  category={category}
                  item={item}
                />
              ))
            : allItemsInShop.map((el) => (
                <ShopItem
                  key={el.id}
                  category={el.category}
                  item={el}
                />
              ))}
        </div>
      </div>
    </>
  );
};

export default ItemsShowcase;
