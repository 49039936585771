import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

import { second, third, fourth, fifth } from "../../assets/assets.js";
import "./slider.styles.scss";

export default class SlickSlider extends React.Component {
  render() {
    const settings = {
      infinite: true,
      speed: 1800,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      cssEase: "linear",
      swipeToSlide: true,
      centerMode: true,
    };

    return (
      <Slider {...settings} className="slider">
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dejrg86ln/image/upload/v1666781750/lydiaugochi.com/DSC_9977_lnoerz.jpg")`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img large"
            style={{
              backgroundImage: `url(${second})`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img largest"
            style={{
              backgroundImage: `url(${third})`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img large"
            style={{
              backgroundImage: `url(${fourth})`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url(${fifth})`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dejrg86ln/image/upload/v1666617476/lydiaugochi.com/IMG-20220731-WA0040_bthk62.jpg")`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dejrg86ln/image/upload/v1666778536/lydiaugochi.com/skul_xbv5me.jpg")`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dejrg86ln/image/upload/v1666779022/lydiaugochi.com/carousel-1_11zon_ydgxhe.jpg")`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dejrg86ln/image/upload/v1666781917/lydiaugochi.com/IMG_3082_dz5qor.jpg")`,
            }}
          ></div>
        </div>
        <div className="slider__item">
          <div
            className="slider__img small"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dejrg86ln/image/upload/v1666779022/lydiaugochi.com/carouse3-1_11zon_ydgxhe.jpg")`,
            }}
          ></div>
        </div>

      </Slider>
    );
  }
}
