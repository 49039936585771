import React from "react";
import "./hero-section.styles.scss";
import { Link } from "react-router-dom";
import SlickSlider from "../slider/slider.component";
import TextAnimation from "./TextAnimation";
import Audio from "./Audiofile";

const Hero2 = () => {
  return (
    <div className="position-relative-wrap">
      <div className="hero">
        <div className="hero__container center-text">
          <h1 style={{fontSize:"3rem", fontWeight:"bolder", color:"white"}}>I'm Lydia Ugochi</h1>
          <h2>
          <TextAnimation/>

            </h2>
          <Link to="/Music" className="hero__cta">
            Check out my Music
          </Link>
        </div>

        <div className="hero__slider">
        </div>
      </div>
    </div>
  );
};

export default Hero2;
