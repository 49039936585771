import { saveAs } from "file-saver";
import React, { useState } from 'react';
import { Button } from "./Button";
import './Audiofile.css'

const SONG_FILE_URL = 'https://lydiaugochi.com/Ebara_Lydia_Ugochi_Trouble_Maker.mp3'


function Audio() {

const downloadFileAtURL=(url) => {
  const fileName = url.split("/").pop();
  const aTag = document.createElement("a"); 
  aTag.href = url; 
  aTag.setAttribute("download", fileName); 
  document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();

}

  return (
    <div className="audio">
      <Button onClick={()=>{downloadFileAtURL(SONG_FILE_URL)}}>Download Now</Button>
    </div>
  );
}

export default Audio;