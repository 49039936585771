import React, { useEffect } from "react";
import "./header.styles.scss";
import {
  InitHamburgerAnimation,
  menuAnimation,
  menuLinkAnimation,
} from "../../js/animations";
import { Link } from "react-router-dom";
import { auth } from "../../firebase/firebase.utils";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";
import styled, { css } from "styled-components/macro";
import Bars from "./bars.svg";

import { selectCurrentUser } from "../../redux/user/user.selectors";
import { setCurrentUser } from "../../redux/user/user.actions";

import { selectCartHidden } from "../../redux/cart/cart.selectors";
import CartIcon from "../cart-icon/cart-icon.component";
import CartPreview from "../cart-preview/cart-preview.component";
import { logo, menuImages } from "../../assets/assets";

import Modal from "../modal/added-to-cart.component";

const Header = (props) => {
  useEffect(() => {
    InitHamburgerAnimation();
    menuAnimation();
    menuLinkAnimation();
  });

  const { currentUser, hidden } = props;
  return (
    <div className="header">
      <Link to="/#" className="anim__hide">
        {" "}
        <img src={logo} alt="logo" className="header__logo " />
      </Link>
      <div className="header__right">
        <Link to="/#" className="header__item anim__hide ">
          Home
        </Link>
        <Link to="/#" className="header__item anim__hide">
          About
        </Link>
        <Link to="/music" className="header__item anim__hide">
          Music
        </Link>
        <Link to="/bookings" className="header__item anim__hide">
          Bookings 
        </Link>
        <Link to="/#" className="header__item anim__hide">
          Blog
        </Link>
        <Link to="/" className="header__item anim__hide">
          Gallery
        </Link>
       
        {}
        {currentUser ? (
          <div
            className="header__item anim__hide"
            onClick={() => {
              auth.signOut();
            }}
          >
          </div>
        ) : (
          <Link to="/login" className="header__item anim__hide">
          </Link>
        )}
        <CartIcon />

        <Modal />

        <div className="hamburger" id="hamburger">
      <MenuBars  />
          {/* <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span> */}
        </div>
      </div>
      {hidden ? null : <CartPreview />}

      <nav className="menu-nav">

        <div className="menu-nav__right">
         
          <div className="menu-nav__main">
            <Link to="/#" className="menu-link">
              <h2 id="1" className="menu-nav__link">
                Home
              </h2>
            </Link>
            <Link to="/#" className="menu-link">
              <h2 id="3" className="menu-nav__link">
                About
              </h2>
            </Link>
            <Link to="/Music" className="menu-link">
              <h2
                id="2"
                className="menu-nav__link"
                onClick={menuAnimation.toggleMenu}
              >
                Music 
              </h2>
            </Link>
            <Link to="/bookings" className="menu-link">
              <h2 id="3" className="menu-nav__link">
                Bookings 
              </h2>
            </Link>
            <Link to="/#" className="menu-link">
              <h2 id="3" className="menu-nav__link">
                Gallery
              </h2>
            </Link>

              <Link to="/donate" className="menu-link">
                <h2 id="4" className="menu-nav__link">
                  Donate
                </h2>{" "}
              </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden,
});

const matchDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, matchDispatchToProps)(Header);

const MenuBars = styled.i`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    background-image: url(${Bars});
    background-size: contain;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 25%);
  }`