import React, { Component } from "react";
import style from "./Artists.module.css";

class Artists extends Component {
  render() {
    return (
      <>
  

        <div className="container-fluid">
          
        <div className={`${style.box} col-xs-10 col-md-4`}>
              <a href="/music" >
                <img src="https://res.cloudinary.com/dejrg86ln/image/upload/v1705573272/lydiaugochi.com/photo_2024-01-18_13-20-35_qzwlbt.jpg" alt="Lydia Ugochi" className={style.Artists} />
                {/* <h1 style={{fontSize:'1rem', color:'white', textDecoration:"none", textAlign:'center'}}>Your Body - Lydia Ugochi</h1> */}
                <div className="dbtn">
 </div>           
              </a>
              </div>
 
              </div>
      </>

    );
  }
}
export default Artists;
