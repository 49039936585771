import React, { Component } from "react";
import style from "./Artists.module.css";
import Audio from '../components/hero/Audiofile'
class Order extends Component {
  render() {
    return (
      <>
  

        <div className="container-fluid">
          
        <div className={`${style.box} col-xs-10 col-md-4`}>
      
                <img src="https://res.cloudinary.com/dejrg86ln/image/upload/v1705573272/lydiaugochi.com/photo_2024-01-18_13-20-35_qzwlbt.jpg" alt="Lydia Ugochi" className={style.Artists} />
                {/* <h1 style={{fontSize:'1rem', color:'white', textDecoration:"none", textAlign:'center'}}>Your Body - Lydia Ugochi</h1> */}
                <div className="dbtn"  style={{textAlign:'center', marginBottom:"2rem"}}>
 <h1 style={{fontSize:'1.8rem', color:'white', textDecoration:"none", textAlign:'center'}}>Thank you for supporting Lydia Ugochi, Please download your order below </h1> 

<Audio/>

 </div>           

 </div>
 </div>
          
      </>

    );
  }
}
export default Order;
