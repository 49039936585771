import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
   display: inline-block;
    background-color: darkolivegreen;
    color: white;
    padding: 1.4vw 3vw;
    font-weight: bold;
    margin-top: 2.6rem;
  border: 2px darkolivegreen solid;
  max-width: 200px;
  //cursor: pointer;
  //text-decoration: none;
  transition: 0.3s;
 
`;
