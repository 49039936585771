import React, { useEffect } from "react";
import "./homepage.scss";
import Hero2 from "../components/hero/hero.component";
import { ScrollTriggerAnimations } from "../js/animations";
import { connect } from "react-redux";
import { toggleModalHidden } from "../redux/product-modal/product-modal.actions";
import Events from "../components/Events/App";
import Artists from "./Artists";
import CollectionPreview from "../components/collections-preview/collections-preview.component";
import SlickSlider from "../components/slider/slider.component";
import FormInput from "../components/form-input/form-input.component";
import { lydia, hero2 } from "../assets/assets";
import { VideoIsh, PlaySVG } from "../assets/assets";

const HomePage = ({ setModalHidden }) => {
  useEffect(() => {
    ScrollTriggerAnimations();
    setModalHidden(true);
  }, [setModalHidden]);

  return (
    <div className="home-page">
      <Hero2 />

      <section className="section__about anim-content">
        <div className="about__content">
          <div className="about__content--text">
            <h2 className="title__sub">
              Welcome to the World of Lydia Ugochi
            </h2>
            <p className="paragraph">
              Lydia Ugochi is an artist, vocalist, and Mugisu from Mbale,
              currently pursuing her music career in Kasese, Western Uganda.
              Raised by Nigerians, she was given the name Ugochi, meaning God's
              Pride. Lydia started singing in the church choir at a young age,
              where she honed her vocal and musical skills. She holds a degree
              in Education in Literature from Makerere University.
            </p>
          </div>

          <div className="about__content--img img">
            <img src={lydia} alt="Lydia Ugochi" className="img-1" />
            <div className="img-2__banner">
              <img src={hero2} alt="Hero Banner" className="img-2" />
            </div>
          </div>
        </div>
      </section>

      <h1 className="latest-project-title">LATEST PROJECT</h1>
      <Artists />

      <div className="events-container">
        <h1 className="lioness-title">DA LIONESS</h1>
        <SlickSlider />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setModalHidden: (value) => dispatch(toggleModalHidden(value)),
});

export default connect(null, mapDispatchToProps)(HomePage);
