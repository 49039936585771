import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectProductForModal } from "../../redux/product-modal/product-modal.selector";
import { toggleModalHidden } from "../../redux/product-modal/product-modal.actions";
import { addItem } from "../../redux/cart/cart.actions";
import { AddedToCartModal as addedToCartModal } from "../../js/animations";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import 'react-h5-audio-player/src/styles.scss';
import "./product-modal.styles.scss";

const ProductModal = ({ product, toggleModalHidden, addItemToCart }) => {
  console.log(product);

  return (
    <div className="product-modal__container" onClick={() => toggleModalHidden()}>
      <div className="product-modal__modal" onClick={(e) => e.stopPropagation()}>
        <button className="product-modal__close-btn" onClick={(e) => {
          e.stopPropagation();
          toggleModalHidden();
        }}>
          ×
        </button>
        <img className="product-modal__img" src={product.imageUrl} alt="product" />
        <div className="product-modal__details">
          <p className="product-modal__product-name">{`${product.name}`}</p>
          <AudioPlayer autoPlay src={product.url} onPlay={(e) => console.log("onPlay")} />
          <div className="product-modal__actions">
            <p className="product-modal__price"> UGX {product.price}</p>
            <button className="add-to-cart" onClick={(e) => {
              e.stopPropagation();
              toggleModalHidden();
              addItemToCart(product);
              addedToCartModal();
            }}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  product: selectProductForModal,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalHidden: () => dispatch(toggleModalHidden()),
  addItemToCart: (item) => dispatch(addItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
