export const first = require("./img/song1.jpg");
export const second = require("./img/carousel-2.jpg");
export const third = require("./img/carousel-2.jpg");
export const fourth = require("./img/carousel-4.jpg");
export const fifth = require("./img/hero2.jpg");
export const six = require("./img/yono.jpg");

export const imgAbout1 = require("./img/about-1.jpg");
export const imgAbout2 = require("./img/about-2.jpg");
export const imgEthos1 = require("./img/ethos-1.jpg");
export const imgEthos2 = require("./img/ethos-2.jpg");
export const lydia = require("./img/lydia.jpg");
export const hero2 = require("./img/hero2.jpg");

export const imgChair = {
  first: require("./img/song1.jpg"),
  second: require("./img/song1.jpg"),
  third: require("./img/song1.jpg"),
};
// export const imgLight = {
//   first: require("./img/light-1.jpg"),
//   second: require("./img/light-2.jpg"),
//   third: require("./img/light-3.jpg"),
// };
// export const imgSofa = {
//   first: require("./img/sofa-1.jpg"),
//   second: require("./img/sofa-2.jpg"),
//   third: require("./img/sofa-3.jpg"),
// };
// export const imgStool = {
//   first: require("./img/stool-1.jpg"),
//   second: require("./img/stool-2.jpg"),
//   third: require("./img/stool-3.jpg"),
// };
// export const imgOtto = {
//   first: require("./img/otto-1.jpg"),
//   second: require("./img/otto-2.jpg"),
//   third: require("./img/otto-3.jpg"),
// };

export const iconArrow = require("./img/arrow-down.png");
export const logo = require("./img/ld.png");

export const menuImages = {
  first: require("./img/menu-1.jpg"),
  second: require("./img/menu-2.jpg"),
  third: require("./img/menu-3.jpg"),
  fourth: require("./img/menu-4.jpg"),
};

export const VideoIsh = require("./img/video-ish.jpg");
export const PlaySVG = require("./svg/play-button.svg");
