import React,{useState, useEffect} from 'react';
import {useInterval} from 'react-use'
import styled,{keyframes} from 'styled-components';
export default function TextAnimation() {
    const reactArray = "Da-Lioness".split(" ");
    const addedArray = "".split(" ");
    const swiftArray = " Artist-Actress-Vocalist-Writer".split(" ");
    const [items, setItems] = useState(reactArray)
    const [count, setCount] = useState(0)
    const [play, setPlay] = useState(false)


useInterval(()=>{
    setItems(reactArray)
    setCount(count + 1)

    if (count === 1){
        setCount(0)
        setItems(swiftArray)
    }
  
}, 
play ? 10000 : null
)

useEffect(() => {
    const timer = setTimeout(() =>{
    

        setItems(swiftArray)
        setPlay(true)
        }, 9000)
        return () => clearTimeout(timer)
}, )
  return <Wrapper>{items.map((item, index)=> (<span key={index}>{item}</span>))}</Wrapper>
  
}

const animation =keyframes`
0% {opacity:0; transform:translateY(-100px) skewY(10deg)skewY(10deg) rotateZ(30deg); filter:blur(10px);}
25% {opacity:1; transform:translateY(0px) skewY(0deg)skewY(0deg) rotateZ(0deg); filter:blur(0px);}
75% {opacity:1; transform:translateY(0px) skewY(0deg)skewY(0deg) rotateZ(0deg); filter:blur(0px);}
10% {opacity:0; transform:translateY(-100px) skewY(10deg)skewY(10deg) rotateZ(30deg); filter:blur(10px);}
`
const Wrapper =styled.span`
display: inline-block;
span{opacity: 0;
animation-name:${animation} ;
animation-duration:10s;
animation-fill-mode: forwards;
animation-iteration-count: infinite;
color: white;

}

`