const SHOP_DATA = {
  Chairs: {
    id: 1,
    category: "Chairs",
    routeName: "chairs",
    items: [
      {
        id: 1,
        category: "Home ",
        name: "Ebara",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1705573272/lydiaugochi.com/photo_2024-01-18_13-20-35_qzwlbt.jpg",
        price: 5000,
        url:"https://res.cloudinary.com/dejrg86ln/video/upload/v1705573274/lydiaugochi.com/Ebara_-_Lydia_Ugochi_Ft_Trouble_Maker_122723_2_lueh7l.mp3"
      },
      {
        id: 1,
        category: "Home ",
        name: "Don't Go",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1705583987/photo_2023-10-31_00-09-32_eds1qe.jpg",
        price: 5000,
        url:"https://res.cloudinary.com/dejrg86ln/video/upload/v1682704710/lydia_tk75n4.mp3"
      },
      {
        id: 1,
        category: "Home ",
        name: "Party",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1682701635/Party_Poster_copy-min_swqkgz.jpg",
        price: 5000,
        url:"https://res.cloudinary.com/dejrg86ln/video/upload/v1682704710/lydia_tk75n4.mp3"
      },
      {
        id: 1,
        category: "Home ",
        name: "Your Body",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1674517495/lydiaugochi.com/body_2_xbnyjd.jpg",
        price: 5000,
        url:"https://res.cloudinary.com/dejrg86ln/video/upload/v1674655150/lydiaugochi.com/yo_body_sample_qln0e1.mp3"
      },
      {
        id: 1,
        category: "Home ",
        name: "Yono",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1674517457/lydiaugochi.com/yono_a9a6o0.jpg",
        price: 5000,
        url:"https://res.cloudinary.com/dejrg86ln/video/upload/v1666616464/lydiaugochi.com/yono_llzpjt.mp3"
      },
      {
        id: 2,
        category: "Lydia Ugochi ",
        name: "Doctor",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1666613656/lydiaugochi.com/Lydia_-_Doctor_qwp0o5.jpg",
        price: 5000,
      },
      {
        id: 3,
        category: "Lydia Ugochi ",
        name: "Pili Pili ft Tiptony",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1666616938/lydiaugochi.com/Royalty_-_Made_with_PosterMyWall_gik6vz.png",
        price: 5000,
      },
      {
        id: 4,
        category: "Lydia Ugochi ",
        name: "Tiktok ft Goodhope",
        imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1666616273/lydiaugochi.com/FB_IMG_1666554216951_mnvkze.jpg",
        price: 5000,
      },
      {
      id: 5,
      category: "Lydia Ugochi ",
      name: "Kabiscuit",
      imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1666614856/lydiaugochi.com/FB_IMG_1666554154004_yuledv.jpg",
      price: 5000,
    },

    {
      id: 7,
      category: "Lydia Ugochi ",
      name: "Commando (Cover)",
      imageUrl: "https://res.cloudinary.com/dejrg86ln/image/upload/v1666617476/lydiaugochi.com/IMG-20220731-WA0040_bthk62.jpg",
      price: 5000,
      url:"https://res.cloudinary.com/dejrg86ln/video/upload/v1666616464/lydiaugochi.com/yono_llzpjt.mp3"
    },

  //     {
  //       id: 3,
  //       category: "Chairs",
  //       name: "Aphrodite",
  //       imageUrl: "https://i.ibb.co/HqFDK4Z/aphrodite.jpg",
  //       price: 994,
  //     },
  //     {
  //       id: 4,
  //       category: "Chairs",
  //       name: "Ameira",
  //       imageUrl: "https://i.ibb.co/9tF0C7D/Ameira.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 5,
  //       name: "Paris",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/B2XD5jH/paris.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 6,
  //       name: "Orpheus",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/tpbL397/orpheus.jpg",
  //       price: 789,
  //     },
  //     {
  //       id: 7,
  //       name: "Homer",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/2Pspvqc/homer.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 8,
  //       name: "Hermes",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/bFgbn6p/Hermes.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 9,
  //       name: "Icarus",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/zQf07yK/icarus.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 10,
  //       name: "Marbisa",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/kqj9NjJ/mabrisa.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 11,
  //       name: "Harshaw",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/F3p2rY1/harshaw.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 12,
  //       name: "Leander",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/qCQSsWj/Leander.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 13,
  //       category: "Chairs",
  //       name: "Brondon",
  //       imageUrl: "https://i.ibb.co/7kRVNWF/brondon.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 14,
  //       category: "Chairs",
  //       name: "Baerus",
  //       imageUrl: "https://i.ibb.co/sR13b0N/baerus.jpg",
  //       price: 25,
  //     },
  //     {
  //       id: 15,
  //       name: "Athena",
  //       category: "Chairs",
  //       imageUrl: "https://i.ibb.co/V2wMJvZ/athena.jpg",
  //       price: 25,
  //     },
  //   ],
  // },

  // Lights: {
  //   id: 2,
  //   category: "Lights",
  //   routeName: "lights",
  //   items: [
  //     {
  //       id: 16,
  //       name: "Victoria",
  //       category: "Lights",
  //       imageUrl: "https://i.ibb.co/z4svZrF/Victoria.jpg",
  //       price: 199,
  //     },
  //     {
  //       id: 17,
  //       name: "Dracai",
  //       category: "Lights",
  //       imageUrl: "https://i.ibb.co/wdFBNs4/dracai.jpg",
  //       price: 199,
  //     },
  //     {
  //       id: 18,
  //       name: "Jason",
  //       category: "Lights",
  //       imageUrl: "https://i.ibb.co/Fn7xzjJ/jason.jpg",
  //       price: 199,
  //     },
  //     {
  //       id: 19,
  //       name: "Hera",
  //       category: "Lights",
  //       imageUrl: "https://i.ibb.co/TmTqGFw/hera.jpg",
  //       price: 199,
  //     },
  //     {
  //       id: 20,
  //       name: "Odysseus",
  //       category: "Lights",
  //       imageUrl: "https://i.ibb.co/9nQkSJ3/odysseus.jpg",
  //       price: 199,
  //     },
  //   ],
  // },

  // Sofas: {
  //   id: 3,
  //   category: "Sofas",
  //   routeName: "sofas",
  //   items: [
  //     {
  //       id: 21,
  //       name: "Carmenta",
  //       category: "Sofas",
  //       imageUrl: " https://i.ibb.co/CHgQxnQ/carmenta.jpg",
  //       price: 19,
  //     },
  //     {
  //       id: 22,
  //       name: "Bachus",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/jRSF2yN/bachus.jpg",
  //       price: 19,
  //     },
  //     {
  //       id: 23,
  //       name: "Clementia",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/sqb4QT7/clementia.jpg ",
  //       price: 219,
  //     },
  //     {
  //       id: 24,
  //       name: "Epona",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/09knDX5/epona.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 25,
  //       name: "Fides",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/J37FPmc/fides.jpg",
  //       price: 19,
  //     },
  //     {
  //       id: 26,
  //       name: "Honos",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/jGwGN7F/honos.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 27,
  //       name: "Libitina",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/bdsV9m0/libitina.jpg ",
  //       price: 39,
  //     },
  //     {
  //       id: 28,
  //       name: "Luna",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/9hrT6qL/luna.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 29,
  //       name: "Mitras",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/1rP8H9X/mitras.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 30,
  //       name: "Prosperina",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/CmV0hVB/prosperina.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 31,
  //       name: "Sancus",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/jLJ1pgp/sancus.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 32,
  //       name: "Tempestes",
  //       category: "Sofas",
  //       imageUrl: "https://i.ibb.co/6yGrmpV/tempestes.jpg ",
  //       price: 19,
  //     },
  //   ],
  // },
  // Stools: {
  //   id: 4,
  //   category: "Stools",
  //   routeName: "stools",
  //   items: [
  //     {
  //       id: 33,
  //       name: "Aesir",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/dML4602/aesir.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 34,
  //       name: "Bor",
  //       category: "Stools",
  //       imageUrl: " https://i.ibb.co/Yp7scHM/bor.jpg ",
  //       price: 19,
  //     },

  //     {
  //       id: 35,
  //       name: "Freyr",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/VjsCg8r/freyr.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 36,
  //       name: "Heimdall",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/G2H61vh/heimdall.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 37,
  //       name: "Hod",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/Ns4MLMQ/hod.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 38,
  //       name: "Loki",
  //       category: "Stools",
  //       imageUrl: " https://i.ibb.co/hCvztNQ/loki.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 238,
  //       name: "Mimir",
  //       category: "Stools",
  //       imageUrl: " https://i.ibb.co/mJ8PLKv/mimir.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 39,
  //       name: "Saga",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/YZJk6gG/saga.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 40,
  //       name: "Vanir",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/S5chgQW/vanir.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 41,
  //       name: "Ban",
  //       category: "Stools",
  //       imageUrl: "https://i.ibb.co/4RjBtRw/ban.jpg",
  //       price: 19,
  //     },
  //   ],
  // },

  // Ottomans: {
  //   id: 5,
  //   category: "Ottomans",
  //   routeName: "ottomans",
  //   items: [
  //     {
  //       id: 42,
  //       name: "Febris",
  //       category: "Ottomans",
  //       imageUrl: " https://i.ibb.co/4fV75Hd/febris.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 43,
  //       name: "Cerburus",
  //       category: "Ottomans",
  //       imageUrl: "https://i.ibb.co/Bw55qtn/cerburus.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 44,
  //       name: "Funus",
  //       category: "Ottomans",
  //       imageUrl: "https://i.ibb.co/R35ZtFf/funus.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 45,
  //       name: "Grateia",
  //       category: "Ottomans",
  //       imageUrl: "https://i.ibb.co/5YKzZ2H/grateia.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 46,
  //       name: "Janus",
  //       category: "Ottomans",
  //       imageUrl: "https://i.ibb.co/MhvNwds/janus.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 47,
  //       name: "Maricus",
  //       category: "Ottomans",
  //       imageUrl: "https://i.ibb.co/LkJj3hM/maricus.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 48,
  //       name: "Nerio",
  //       category: "Ottomans",
  //       imageUrl: " https://i.ibb.co/7JG3LXr/nerio.jpg ",
  //       price: 19,
  //     },
  //     {
  //       id: 49,
  //       name: "Hercules",
  //       category: "Ottomans",
  //       imageUrl: "  https://i.ibb.co/1zCmJB3/hercules.jpg",
  //       price: 19,
  //     },
    ],
  },
};

export default SHOP_DATA;
